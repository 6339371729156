import { useCallback, useEffect, useRef, useState } from 'react'
import { LockerInfoWithValue } from '../../qubit/lib/types'
import { getLocker, ZERO } from '../../qubit/utils'
import useQubit from '../useQubit'
import useBlock from '../useBlock'
import { singletonHook } from 'react-singleton-hook'
import { Contracts, QBTAddress } from '../../qubit/lib/constants'


const init: LockerInfoWithValue = {
    attached: false,
    address: Contracts.Locker,
    token: QBTAddress,
    name: 'QBT',
    locked: ZERO, totalLocked: ZERO,
    score: ZERO, totalScore: ZERO,
    available: ZERO,
    avgBoost: null,
    expiry: null
}

const useLockerImpl = () => {
    const mountRef = useRef(false)
    const [locker, setLocker] = useState(init)
    
    const qubit = useQubit()
    const { bscBlock } = useBlock()
    
    const handleLocker = useCallback(async () => {
        try {
            const result = await getLocker(qubit)
            
            if (!mountRef.current) return
            setLocker(result)
        } catch {
            console.error('handleLocker')
        }
    }, [qubit, setLocker])
    
    useEffect(() => {
        if (qubit) {
            handleLocker()
        }
    }, [qubit, bscBlock, handleLocker])
    
    useEffect(() => {
        mountRef.current = true
        return () => {
            mountRef.current = false
        }
    }, [mountRef])
    
    return locker
}

const useLocker = singletonHook(init, useLockerImpl)
export default useLocker
