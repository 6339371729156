import { useContext } from 'react'
import { Context } from '../../contexts/Web3SideProvider'


const useSideWeb3 = () => {
    const { bsc } = useContext(Context)
    return { bsc }
}

export default useSideWeb3
