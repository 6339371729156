import React, { createContext, useEffect, useState } from 'react'
import useActiveWeb3React from '../../hooks/connections/useActiveWeb3React'
import Qubit from '../../qubit'
import useFirebase from '../../hooks/useFirebase'
import useSideWeb3 from '../../hooks/connections/useSideWeb3'
import { shortenAddress } from '../../qubit/utils'


export interface QubitContext {
    qubit?: Qubit
}

export const Context = createContext<QubitContext>({ qubit: undefined })

const QubitProvider: React.FC = ({ children }) => {
    const [bunny, setBunny] = useState<any>()
    
    const { account, library } = useActiveWeb3React()
    const { bsc } = useSideWeb3()
    const firebase = useFirebase()
    
    useEffect(() => {
        if (!library) return
        
        setBunny(new Qubit(account, library, bsc))
    }, [library, account, bsc])
    
    useEffect(() => {
        if (account) {
            firebase.analytics().setUserId(shortenAddress(account), { global: true })
        }
    }, [account, firebase])
    
    return <Context.Provider value={{ qubit: bunny }}>{children}</Context.Provider>
}

export default QubitProvider
