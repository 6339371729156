import { useContext } from 'react'
import { Context } from '../contexts/QubitProvider'


const useQubit = () => {
    const { qubit } = useContext(Context)
    return qubit
}

export default useQubit
