import React from 'react'
import './GroupDivider.scss'


const GroupColDivider: React.FC = ({ children }) => {
    return (
        <div className='group-col-divider'/>
    )
}

export default GroupColDivider