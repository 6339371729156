import logoSymbol from './qubit-logo.svg'
import logoLabel from './qubit-label.svg'
import logoYellow from './qubit_logo_yellow.png'
import logoBunny from './bunny-logo.svg'


export const assetLogos: Record<string, string> = {
    symbol: logoSymbol,
    label: logoLabel,
    symbolYellow: logoYellow,
    symbolBunny: logoBunny
}
