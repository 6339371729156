import React from 'react'


const PageContent: React.FC = ({ children }) => {
    return (
        <div className='page-content'>
            {children}
        </div>
    )
}

export default PageContent
