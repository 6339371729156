import {Link, useHistory, useLocation} from "react-router-dom";
import {ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {assetIcons} from "../../../../../assets/icons";
import {supportedMarkets} from "../../../../../qubit/lib/constants";
import React, {useEffect, useState} from "react";

const InfoDetailTitle: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    // to select market
    const [marketName, setMarketName] = useState("")
    const [marketIdx, setMarketIdx] = useState(0);
    const [marketInfo, setMarketInfo] = useState(supportedMarkets[marketIdx])

    // dropdown
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleMarketSelected = (event: number) => {
        setMarketIdx(event);
        history.push(`/info/${supportedMarkets[event].name}`, {market: supportedMarkets[event]})
    };

    useEffect(() => {
        if (location.state) {
            setMarketName((location.state as any).market.name)
            setMarketInfo((location.state as any).market)
        }
    },[marketIdx])

    const [state, setState] = useState({
        mobileView: false
    })
    const { mobileView } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 768
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);



    const handleBack = (e: any) => {
        history.push('/info')
    }

    return (
        <div className='market-title'>
            {mobileView
                ? <img className='back-icon clickable' onClick={handleBack} src={assetIcons.back} alt="뒤로가기" />
                : <div className='same-line'>
                    <Link to={'/info'} >Market</Link>
                    <div>{" < " + marketName}</div>
                </div>
            }


            <div className='same-line space relative'>

                <div className='same-line market-title-underlined'>
                    <img className='coin-icon' src={marketInfo.icon} alt={marketName}/>
                    <span>{marketName}</span>
                </div>

                <IconButton aria-controls={open ? 'menu-list-grow' : undefined}
                            className='dropdown-icon'
                            aria-haspopup="true"
                            onClick={handleToggle} ref={anchorRef}>
                    <img  src={assetIcons.dropdown} alt='close'/>
                </IconButton>

                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 99}}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {supportedMarkets.map((market, idx) =>
                                            <MenuItem value={idx} onClick={(e) => {
                                                handleMarketSelected(idx);
                                                handleClose(e);
                                            }}>
                                                <div className='same-line'
                                                     key={`market-group-item_${idx}`}>
                                                    <img src={market.icon} alt={market.name} style={{width: '32px', height: '32px', marginRight: '8px'}}/>
                                                    <div className='market-item'>{market.name}</div>
                                                </div>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </div>
        </div>
    );


}

export default InfoDetailTitle
