import { MarketInfo } from './types'
import { assetTokens } from '../../assets/tokens'


export const WBNB: string = '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd'
export const QBTAddress: string = '0xF523e4478d909968090a232eB380E2dd6f802518'
export const BUNNY_BNB: string = '0x5aFEf8567414F29f0f927A0F2787b188624c10E2'

export const Contracts = {
    Dashboard: '0x5bA1B272D60f46371279aE7a1C13227Fb93F99c1',
    PriceCalculator: '0x20E5E35ba29dC3B540a1aee781D0814D5c77Bce6',
    Locker: '0xeA34f39DF510eAFFb789d575c9aa800d61476256',
    Qore: '0xb3f98A31A02d133f65da961086EcDa4133bdf48e',
    Distributor: '0xA2897BEDE359e56F67D06F42C39869719E59bEcc',
    Presale: '',
}

export const markets: Record<string, any> = require('./data/markets.json')
export const supportedMarkets: MarketInfo[] = Object.keys(markets).map(name => {
    const market = markets[name]
    return market.closed ? null : {
        icon: assetTokens[name],
        name: name,
        address: market.address,
        underlying: market.underlying
    }
}).filter(each => !!each)
