import React, { createContext, useCallback, useState } from 'react'
import { MarketInfo, MarketTypes } from '../../qubit/lib/types'
import './Modals.scss'


interface ModalsContext {
    marketInfo?: MarketInfo
    marketType?: MarketTypes
    content?: React.ReactNode
    isOpen?: boolean
    onPresent: (content: React.ReactNode, marketInfo?: MarketInfo, marketType?: MarketTypes) => void
    onDismiss: () => void
}

export const Context = createContext<ModalsContext>({
    onPresent: () => {
    },
    onDismiss: () => {
    }
})

const ModalsProvider: React.FC = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [content, setContent] = useState<React.ReactNode>()
    const [marketInfo, setMarketInfo] = useState(undefined)
    const [marketType, setMarketType] = useState(undefined)
    
    const handlePresent = useCallback((modalContent: React.ReactNode, marketInfo?: MarketInfo, marketType?: MarketTypes) => {
        setMarketInfo(marketInfo)
        setMarketType(marketType)
        setContent(modalContent)
        setIsOpen(true)
    }, [setMarketInfo, setMarketType, setContent, setIsOpen])
    
    const handleDismiss = useCallback(() => {
        setMarketInfo(undefined)
        setMarketType(undefined)
        setContent(undefined)
        setIsOpen(false)
    }, [setMarketInfo, setMarketType, setContent, setIsOpen])
    
    return (
        <Context.Provider value={{ marketInfo, marketType, content, isOpen, onPresent: handlePresent, onDismiss: handleDismiss }}>
            {children}
            {isOpen && (
                <div className='modal-wrapper'>
                    <div className='modal-backdrop' onClick={handleDismiss}/>
                    {React.isValidElement(content) && React.cloneElement(content, {
                        marketInfo, marketType, onDismiss: handleDismiss
                    })}
                </div>
            )}
        </Context.Provider>
    )
}

export default ModalsProvider
