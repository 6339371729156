import React from 'react'
import Footer from './components/Footer'
import './Page.scss'


function Page(props: any) {
    return (
        <div>
            <div className={'page ' + props.bgColor}>{props.children}</div>
            <div className='page-footer'>
                <Footer/>
            </div>
        </div>
    )
}

export default Page
