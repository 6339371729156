import React, { createContext } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'


const app = firebase.initializeApp({
    apiKey: "AIzaSyA4h_Qqv4smrXOo5lmDqjYJOfnvdrcXFz4",
    authDomain: "qubit-finance.firebaseapp.com",
    projectId: "qubit-finance",
    storageBucket: "qubit-finance.appspot.com",
    messagingSenderId: "321144747955",
    appId: "1:321144747955:web:f669e328cc0f8ed4743dc6",
    measurementId: "G-QQ64L2S5WJ"
})
firebase.analytics()

export interface FirebaseContext {
    app?: firebase.app.App
    uid?: string
}

export const Context = createContext<FirebaseContext>({ app: undefined, uid: undefined })

const FirebaseProvider: React.FC = ({ children }) => {
    return <Context.Provider value={{ app: app }}>{children}</Context.Provider>
}

export default FirebaseProvider
