import { useCallback, useEffect, useRef, useState } from 'react'
import { MarketInfoWithValue } from '../../qubit/lib/types'
import { getMarkets, ZERO } from '../../qubit/utils'
import useQubit from '../useQubit'
import useBlock from '../useBlock'
import usePrices from '../price/usePrices'
import { singletonHook } from 'react-singleton-hook'
import { supportedMarkets } from '../../qubit/lib/constants'


const init: MarketInfoWithValue[] = supportedMarkets.map(market => {
    return {
        ...market,
        attached: false,
        membership: false,
        apySupply: 0, apySupplyQBT: 0, apyMySupplyQBT: 0,
        apyBorrow: 0, apyBorrowQBT: 0, apyMyBorrowQBT: 0,
        collateralFactor: ZERO,
        liquidity: ZERO, liquidityInUSD: ZERO,
        supply: ZERO, supplyInUSD: ZERO,
        borrow: ZERO, borrowInUSD: ZERO,
        totalBorrow: ZERO, totalSupply: ZERO,
        supplyBoosted: ZERO, borrowBoosted: ZERO,
        totalSupplyBoosted: ZERO, totalBorrowBoosted: ZERO
    }
})

const useMarketsImpl = () => {
    const mountRef = useRef(false)
    const [markets, setMarkets] = useState([] as MarketInfoWithValue[])
    
    const qubit = useQubit()
    const prices = usePrices()
    const { bscBlock } = useBlock()
    
    const handleMarkets = useCallback(async () => {
        try {
            const result = await getMarkets(qubit, prices)
            
            if (!mountRef.current) return
            setMarkets(result)
        } catch {
            console.error('handleMarkets')
        }
    }, [qubit, prices, setMarkets])
    
    useEffect(() => {
        if (qubit) {
            handleMarkets()
        }
    }, [qubit, prices, bscBlock, handleMarkets])
    
    useEffect(() => {
        mountRef.current = true
        return () => {
            mountRef.current = false
        }
    }, [mountRef])
    
    return markets
}

const useMarkets = singletonHook(init, useMarketsImpl)
export default useMarkets
