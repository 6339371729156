import React, { ReactElement } from 'react'
import { Tooltip } from '@material-ui/core'

import './Information.scss'


export interface InformationProps {
    text: string | ReactElement<any, any>
    children: ReactElement<any, any>
    className?: string
}

const Information: React.FC<InformationProps> = ({ text, children , className }) => {
    return (
        <Tooltip title={
            <React.Fragment>
                <div className={`information-text ${className}`}>
                    {text}
                </div>
            </React.Fragment>
        }>
            <div className='information no-select clickable'>
                {children}
            </div>
        </Tooltip>
    )
}

export default Information
