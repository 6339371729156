import tokenQBT from './token-qbt.svg'
import tokenBNB from './token-bnb.svg'
import tokenWBNB from './token-wbnb.svg'
import tokenCAKE from './token-cake.svg'
import tokenBUSD from './token-busd.svg'
import tokenUSDT from './token-usdt.svg'
import tokenVAI from './token-vai.svg'
import tokenBTCB from './token-btcb.svg'
import tokenETH from './token-eth.svg'
import tokenUSDC from './token-usdc.svg'
import tokenDAI from './token-dai.svg'
import tokenDOT from './token-dot.svg'


export const assetTokens: Record<string, string> = {
    QBT: tokenQBT,
    BNB: tokenBNB,
    WBNB: tokenWBNB,
    CAKE: tokenCAKE,
    BUSD: tokenBUSD,
    USDT: tokenUSDT,
    VAI: tokenVAI,
    BTCB: tokenBTCB,
    ETH: tokenETH,
    USDC: tokenUSDC,
    DAI: tokenDAI,
    DOT: tokenDOT,
}
