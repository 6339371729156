import React, { ReactNode } from 'react'
import { MarketInfoWithValue } from '../../../../qubit/lib/types'
import {toDisplayBalanceComma, toDisplayPercent} from '../../../../qubit/utils'
import './InfoMarketGroup.scss'
import useMarkets from '../../../../hooks/pools/useMarkets'
import {useHistory} from "react-router-dom";


const InfoMarketGroup: React.FC = () => {
    const markets = useMarkets()
    const history = useHistory()

    const handleMarketSelected = (market: MarketInfoWithValue) => {
        history.push(`/info/${market.name}`, {market: market})
    }

    const getMarketItemView = (): ReactNode => {
        const itemViews: ReactNode[] = []
        const tempPositiveNum = 0.12
        const tempNegativeNum = -0.12
        
        markets.forEach((each: MarketInfoWithValue, idx: number) => {

            itemViews.push(
                <div className='item-content clickable' key={`market-group-item_${idx}`} onClick={() => handleMarketSelected(each)}>
                    <div className='section market'>
                        <img src={each.icon} alt={each.name}/>
                        <span>{each.name}</span>
                    </div>

                    <div className='section supply'>
                        <span className='content-number'>{toDisplayBalanceComma(280230)}M</span>
                        <span className={'sub-content ' + (tempNegativeNum >= 0 ? 'plus' : 'minus')}>{tempNegativeNum >= 0 ? "+" : ""}{tempNegativeNum}</span>
                    </div>
                    <div className='section sAPY'>
                        <span className='content-number '>{toDisplayPercent(each.apySupply)}% + {toDisplayPercent(each.apySupplyQBT)}%</span>
                        <span className='sub-content'>Max reward {toDisplayPercent(each.apySupplyQBT * 2.5)}%</span>
                    </div>

                    <div className='section borrow'>
                        <span className='content-number'>{toDisplayBalanceComma(280230)}M</span>
                        <span className={'sub-content ' + (tempPositiveNum >= 0 ? 'plus' : 'minus')}>{tempPositiveNum >= 0 ? "+" : ""}{tempPositiveNum}</span>
                    </div>
                    <div className='section bAPY'>
                        <span className='content-number '>-{toDisplayPercent(each.apyBorrow)}% + {toDisplayPercent(each.apySupplyQBT)}%</span>
                        <span className='sub-content'>Max reward {toDisplayPercent(each.apyBorrowQBT * 2.5)}%</span>
                    </div>
                </div>
            )
            
            if (idx < markets.length - 1) {
                itemViews.push(<div className='content-divider' key={`market-item-divider_${idx}`}/>)
            }
        })
        return <div className='market-group-item'>{itemViews}</div>
    }
    
    return (
        <div className='info-card'>
            <div className='info-market-group'>
                <div className='info-series'>
                    <span className='info-series-item market'>Market</span>
                    <span className='info-series-item totalSupply'>Total Supply</span>
                    <span className='info-series-item supplyAPY'>Supply APY</span>
                    <span className='info-series-item totalBorrow'>Total Borrow</span>
                    <span className='info-series-item borrowAPY'>Borrow APY</span>
                </div>

                {getMarketItemView()}
            </div>
        </div>
    )
}

export default InfoMarketGroup
