import React, {useEffect} from 'react'
import {PageContent} from '../../../components/Page'
import InfoDetailGroup from './components/InfoDetailGroup/InfoDetailGroup'
import './InfoDetail.scss'
import InfoDetailPortfolio from "./components/InfoDetailPortfolio";
import InfoDetailChartGroup from "./components/InfoDetailChartGroup/InfoDetailChartGroup";
import InfoDetailTitle from "./components/InfoDetailPortfolio/InfoDetailTitle";


const InfoDetail: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0)

    },[])

    return (
        <>
            <InfoDetailPortfolio/>
            <PageContent>
                <div className='info-detail-group'>

                    <div className='info-detail-group-wrapper reward'>
                        <span className='group-label no-select'>Reward APYs</span>
                        <InfoDetailChartGroup />
                    </div>
                    <div className='info-detail-group-wrapper average'>
                        <span className='group-label no-select'>Average boost</span>
                        <InfoDetailChartGroup />
                    </div>
                    <div className='info-detail-group-wrapper market'>
                        <span className='group-label no-select'>Markets Details</span>
                        <InfoDetailGroup />
                    </div>
                </div>
            </PageContent>
        </>
    )
}

export default InfoDetail
