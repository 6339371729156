import BigNumber from 'bignumber.js'
import { AddressZero, MaxUint256 } from '@ethersproject/constants'
import { Auction, LockerInfoWithValue, MarketInfo, MarketInfoWithValue, PortfolioInfo } from './lib/types'
import { BUNNY_BNB, Contracts, QBTAddress, supportedMarkets } from './lib/constants'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { TransactionDetails } from '../state/transactions/reducer'
import { bscRPCs, ChainInfo } from '../connections/connectors'
import Qubit from './Qubit'


BigNumber.config({
    EXPONENTIAL_AT: [-100, 100],
    DECIMAL_PLACES: 18
})

const DUST = new BigNumber(1000)
const ZERO = new BigNumber(0)
const MWEI = new BigNumber(10).pow(6)
const UNIT = new BigNumber(10).pow(18)
const UINT_MAX = new BigNumber(MaxUint256.toString())
const EMPTY_ADDRESS = AddressZero
const EXPIRY_BASE = 604_800
const EXPIRY_MAX = 63_072_000

export { BigNumber, DUST, ZERO, MWEI, UNIT, UINT_MAX, EMPTY_ADDRESS, EXPIRY_BASE, EXPIRY_MAX }

export const BSC_GUIDE = 'https://docs.binance.org/smart-chain/wallet/metamask.html#connect-your-metakmast-with-binance-smart-chain'

// export const getBscScanTokenLink = (token: string) => `https://bscscan.com/token/${token}`
// export const getBscScanAddressLink = (address: string) => `https://bscscan.com/address/${address}`
// export const getBscScanTxLink = (hash: string) => `https://bscscan.com/tx/${hash}`
// TODO testnet
export const getBscScanTokenLink = (token: string) => `https://testnet.bscscan.com/token/${token}`
export const getBscScanAddressLink = (address: string) => `https://testnet.bscscan.com/address/${address}`
export const getBscScanTxLink = (hash: string) => `https://testnet.bscscan.com/tx/${hash}`

export const snooze = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Display
 * */
export const addComma = (numString: string) => {
    let numParts = numString.split('.')
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return numParts.join('.')
}

export const shortenAddress = (address: string) => {
    if (!address) return ''
    return `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length)}`
}

export const shortenTransaction = (hash: string) => {
    if (!hash) return ''
    return hash.slice(0, 8) + '...' + hash.slice(58, 65)
}

export const toDisplayBalanceComma = (balance: BigNumber | number, decimals = 18, digits: boolean = true): string => {
    const balanceBigNumber: BigNumber = typeof balance === 'number' ? new BigNumber(balance).times(UNIT) : balance
    const displayBalance = balanceBigNumber ? balanceBigNumber.dividedBy(new BigNumber(10).pow(decimals)) : ZERO
    if (displayBalance.lt(1)) {
        return digits ? displayBalance.toPrecision(4) : displayBalance.toString()
    } else {
        return addComma(digits ? displayBalance.toFixed(2) : displayBalance.toFixed(0))
    }
}

export const toDisplayBalanceUnit = (balance: BigNumber | number, decimals = 18, digits: boolean = true): string => {
    const balanceBigNumber: BigNumber = typeof balance === 'number' ? new BigNumber(balance).times(UNIT) : balance
    const displayBalance = balanceBigNumber ? balanceBigNumber.dividedBy(new BigNumber(10).pow(decimals)) : ZERO
    if (displayBalance.lt(1)) {
        return digits ? displayBalance.toPrecision(2) : displayBalance.toString()
    } else if (displayBalance.gte(1_000_000)) {
        return `${addComma(displayBalance.dividedBy(1_000_000).toFixed(2))}M`
    } else if (displayBalance.gte(1_000)) {
        return `${addComma(displayBalance.dividedBy(1_000).toFixed(2))}K`
    } else {
        return addComma(digits ? displayBalance.toFixed(2) : displayBalance.toFixed(0))
    }
}

export const toDisplayBalance = (balance: BigNumber, decimals = 18): string => {
    const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
    if (displayBalance.lt(1)) {
        return displayBalance.toPrecision(4)
    } else {
        return displayBalance.toFixed(2)
    }
}

export const toFullDisplayBalance = (balance: BigNumber, decimals = 18): string => {
    return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
}

export const toDisplayNumber = (balance: BigNumber, decimals = 18): number => {
    const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
    return displayBalance.toNumber()
}

export const toDisplayRatio = (numer: BigNumber, denom: BigNumber): number => {
    if (numer.isZero() || denom.isZero()) return 0
    
    const ratio = numer.times(UNIT).dividedBy(denom)
    return parseFloat(ratio.times(100).dividedBy(UNIT).toString())
}

export const toDisplayPercent = (percent: number, fraction: number = 2): string => {
    if (percent === 0) return '0.00'
    
    if (percent >= 1_000_000) {
        return `${addComma((percent / 1_000_000).toFixed(2))}M`
    } else if (percent >= 1_000) {
        return `${addComma((percent / 1_000).toFixed(2))}K`
    }
    return percent ? percent.toFixed(fraction) : '--.-'
}

export const toDisplayDecimalPoint = (balance: BigNumber, decimals = 18): string => {
    try {
        const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals)).toNumber()
        if (displayBalance >= 0.01) {
            return displayBalance.toFixed(2)
        } else if (displayBalance >= 0.001) {
            return displayBalance.toFixed(3)
        } else if (displayBalance >= 0.0001) {
            return displayBalance.toFixed(4)
        } else if (displayBalance >= 0.00001) {
            return displayBalance.toFixed(5)
        } else if (displayBalance >= 0.000001) {
            return displayBalance.toFixed(6)
        } else if (displayBalance >= 0.0000001) {
            return displayBalance.toFixed(7)
        } else {
            return '0'
        }
    } catch {
        return '0'
    }
}

export const isMore = (target: string, base: string): boolean => {
    return new BigNumber(target).gt(new BigNumber(base))
}

export const minimumOf = (left: BigNumber, right: BigNumber): BigNumber => {
    return left.lt(right) ? left : right
}

export const maximumOf = (left: BigNumber, right: BigNumber): BigNumber => {
    return left.gt(right) ? left : right
}

export const truncateExpiry = (unix: number): number => {
    const dividend = parseInt((unix / EXPIRY_BASE).toString())
    return parseInt((dividend * EXPIRY_BASE).toString())
}

/**
 * network
 * */
export const setupBSCNetwork = async (): Promise<boolean> => {
    try {
        const provider = window.ethereum
        if (!provider) return false
        
        // const bscChain = ChainInfo[56]
        const bscChain = ChainInfo[97] // TODO testnet
        await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: bscChain.chainId,
                    chainName: bscChain.chainName,
                    nativeCurrency: bscChain.nativeCurrency,
                    rpcUrls: bscRPCs,
                    blockExplorerUrls: [bscChain.blockExplorerUrl]
                }
            ]
        })
        return true
    } catch (ex) {
        console.warn('ex', ex, setupBSCNetwork.name)
        return false
    }
}

/**
 * transactions
 * */
export const isRecentTransaction = (tx: TransactionDetails): boolean => {
    return new Date().getTime() - tx.addedTime < 86_400_000
}

export const sortByNewTransactions = (a: TransactionDetails, b: TransactionDetails) => {
    return b.addedTime - a.addedTime
}

/**
 * erc20
 * */
export const getTokenBalance = async (qubit: Qubit, token: string): Promise<BigNumber> => {
    if (!qubit || !qubit.account) return ZERO
    try {
        if (!token) {
            return ZERO
        } else if (token === EMPTY_ADDRESS) {
            const bnbBalance = await qubit.contracts.library.getBalance(qubit.account)
            return new BigNumber(bnbBalance.toString())
        } else {
            const tokenContract = qubit.contracts.getBEP20ReadOnlyContract(token)
            const tokenBalance = await tokenContract.balanceOf(qubit.account)
            return new BigNumber(tokenBalance.toString())
        }
    } catch (ex) {
        console.warn('ex', ex, getTokenBalance.name)
        return ZERO
    }
}

export const getTokenBalanceInUSD = async (qubit: Qubit, token: string, balance: string): Promise<BigNumber> => {
    try {
        const priceCalculator = qubit.contracts.getPriceCalculatorContract()
        const { 1: valueInUSD } = await priceCalculator.valueOfAsset(token, balance)
        return new BigNumber(valueInUSD.toString())
    } catch (ex) {
        console.warn('ex', ex, getTokenBalanceInUSD.name)
        return ZERO
    }
}

export const getTokenAllowance = async (qubit: Qubit, token: string, spender: string): Promise<BigNumber> => {
    if (!qubit || !qubit.account) return ZERO
    try {
        if (!token) {
            return ZERO
        } else if (token === EMPTY_ADDRESS) {
            return UINT_MAX
        } else {
            const tokenContract = qubit.contracts.getBEP20ReadOnlyContract(token)
            const tokenAllowance = await tokenContract.allowance(qubit.account, spender)
            return new BigNumber(tokenAllowance.toString())
        }
    } catch (ex) {
        console.warn('ex', ex, getTokenAllowance.name)
        return ZERO
    }
}

export const getPrices = async (qubit: Qubit): Promise<Record<string, BigNumber>> => {
    const result: Record<string, BigNumber> = {}
    const underlyingList = supportedMarkets.map(market => market.underlying)
    underlyingList.forEach((underlying: string) => result[underlying] = ZERO)
    
    try {
        const priceCalculator = qubit.contracts.getPriceCalculatorContract()
        const priceList = await priceCalculator.pricesOf(underlyingList)
        underlyingList.forEach((underlying: string, idx: number) => result[underlying] = new BigNumber(priceList[idx].toString()))
    } catch (ex) {
        console.warn('ex', ex, getPrices.name)
    }
    return result
}

export const tokenApprove = async (qubit: Qubit, token: string, spender: string): Promise<TransactionResponse> => {
    try {
        const maxUint256 = MaxUint256.toString()
        const tokenContract = qubit.contracts.getBEP20Contract(token)
        
        const gasOptions = await qubit.estimateTxGas(tokenContract.estimateGas.approve(spender, maxUint256))
        return await tokenContract.approve(spender, maxUint256, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, tokenApprove.name)
        return undefined
    }
}

/**
 * Locker
 * */
export const getLocker = async (qubit: Qubit): Promise<LockerInfoWithValue> => {
    const lockerValue: LockerInfoWithValue = {
        attached: false,
        address: Contracts.Locker,
        token: QBTAddress,
        name: 'QBT',
        locked: ZERO, totalLocked: ZERO,
        score: ZERO, totalScore: ZERO,
        available: ZERO,
        avgBoost: null,
        expiry: null
    }
    
    try {
        const account = qubit.account ? qubit.account : EMPTY_ADDRESS
        const dashboard = qubit.contracts.getDashboardContract()
        const info = await dashboard.lockerDataOf(account)
        
        const expiry = parseInt(info.expiry.toString())
        lockerValue.attached = true
        lockerValue.locked = new BigNumber(info.locked.toString())
        lockerValue.totalLocked = new BigNumber(info.totalLocked.toString())
        lockerValue.score = new BigNumber(info.score.toString())
        lockerValue.totalScore = new BigNumber(info.totalScore.toString())
        lockerValue.available = new BigNumber(info.available.toString())
        lockerValue.expiry = expiry > 0 ? expiry : null
    } catch (ex) {
        console.log(ex)
    }
    return lockerValue
}

export const lockerDeposit = async (qubit: Qubit, amount: string, expiry: number): Promise<TransactionResponse> => {
    try {
        const amountWithDecimals = new BigNumber(amount).times(UNIT).toString()
        const lockerContract = qubit.contracts.getLockerContract()
        
        const gasOptions = await qubit.estimateTxGas(lockerContract.estimateGas.deposit(amountWithDecimals, expiry))
        return await lockerContract.deposit(amountWithDecimals, expiry, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, lockerDeposit.name)
        return undefined
    }
}

export const lockerExtend = async (qubit: Qubit, expiry: number): Promise<TransactionResponse> => {
    try {
        const lockerContract = qubit.contracts.getLockerContract()
        const gasOptions = await qubit.estimateTxGas(lockerContract.estimateGas.extendLock(expiry))
        return await lockerContract.extendLock(expiry, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, lockerExtend.name)
        return undefined
    }
}

export const lockerWithdraw = async (qubit: Qubit): Promise<TransactionResponse> => {
    try {
        const lockerContract = qubit.contracts.getLockerContract()
        const gasOptions = await qubit.estimateTxGas(lockerContract.estimateGas.withdraw())
        return await lockerContract.withdraw({ ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, lockerWithdraw.name)
        return undefined
    }
}

/**
 * Markets
 * */
export const getMarkets = async (qubit: Qubit, prices: Record<string, BigNumber>): Promise<MarketInfoWithValue[]> => {
    try {
        const account = qubit.account ? qubit.account : EMPTY_ADDRESS
        const dashboard = qubit.contracts.getDashboardContract()
        const responses: any[] = await dashboard.marketsOf(account, supportedMarkets.map(each => each.address))
        const result = responses.map((info, index: number) => {
            const market = supportedMarkets[index]
            const marketValue: MarketInfoWithValue = {
                ...market,
                attached: false,
                membership: false,
                apySupply: 0, apySupplyQBT: 0, apyMySupplyQBT: 0,
                apyBorrow: 0, apyBorrowQBT: 0, apyMyBorrowQBT: 0,
                collateralFactor: ZERO,
                liquidity: ZERO, liquidityInUSD: ZERO,
                supply: ZERO, supplyInUSD: ZERO,
                borrow: ZERO, borrowInUSD: ZERO,
                totalSupply: ZERO, totalBorrow: ZERO,
                supplyBoosted: ZERO, borrowBoosted: ZERO,
                totalSupplyBoosted: ZERO, totalBorrowBoosted: ZERO
            }
            
            try {
                marketValue.attached = true
                marketValue.membership = info.membership
                marketValue.apySupply = toDisplayRatio(new BigNumber(info.apySupply.toString()), UNIT)
                marketValue.apySupplyQBT = toDisplayRatio(new BigNumber(info.apySupplyQBT.toString()), UNIT)
                marketValue.apyMySupplyQBT = toDisplayRatio(new BigNumber(info.apyMySupplyQBT.toString()), UNIT)
                marketValue.apyBorrow = toDisplayRatio(new BigNumber(info.apyBorrow.toString()), UNIT)
                marketValue.apyBorrowQBT = toDisplayRatio(new BigNumber(info.apyBorrowQBT.toString()), UNIT)
                marketValue.apyMyBorrowQBT = toDisplayRatio(new BigNumber(info.apyMyBorrowQBT.toString()), UNIT)
                marketValue.collateralFactor = new BigNumber(info.collateralFactor.toString())
                marketValue.liquidity = new BigNumber(info.liquidity.toString())
                marketValue.liquidityInUSD = marketValue.liquidity.times(prices[market.underlying]).div(UNIT)
                marketValue.supply = new BigNumber(info.supply.toString())
                marketValue.supplyInUSD = marketValue.supply.times(prices[market.underlying]).div(UNIT)
                marketValue.borrow = new BigNumber(info.borrow.toString())
                marketValue.borrowInUSD = marketValue.borrow.times(prices[market.underlying]).div(UNIT)
            } catch (e) {
                if (window.dev) {
                    console.warn(market.name, e)
                }
            }
            return marketValue
        })
        return result.filter(each => !!each)
    } catch (ex) {
        console.log(ex)
        return []
    }
}

export const getMarket = async (qubit: Qubit, prices: Record<string, BigNumber>, target: string): Promise<MarketInfoWithValue> => {
    try {
        const account = qubit.account ? qubit.account : EMPTY_ADDRESS
        const market = supportedMarkets.find(pool => pool.address === target)
        if (!market) return null
        
        const marketValue: MarketInfoWithValue = {
            ...market,
            attached: false,
            membership: false,
            apySupply: 0, apySupplyQBT: 0, apyMySupplyQBT: 0,
            apyBorrow: 0, apyBorrowQBT: 0, apyMyBorrowQBT: 0,
            collateralFactor: ZERO,
            liquidity: ZERO, liquidityInUSD: ZERO,
            supply: ZERO, supplyInUSD: ZERO,
            borrow: ZERO, borrowInUSD: ZERO,
            totalSupply: ZERO, totalBorrow: ZERO,
            supplyBoosted: ZERO, borrowBoosted: ZERO,
            totalSupplyBoosted: ZERO, totalBorrowBoosted: ZERO
        }
        
        try {
            const dashboard = qubit.contracts.getDashboardContract()
            const info = await dashboard.marketDataOf(account, market.address)
            
            marketValue.attached = true
            marketValue.membership = info.membership
            marketValue.apySupply = toDisplayRatio(new BigNumber(info.apySupply.toString()), UNIT)
            marketValue.apySupplyQBT = toDisplayRatio(new BigNumber(info.apySupplyQBT.toString()), UNIT)
            marketValue.apyMySupplyQBT = toDisplayRatio(new BigNumber(info.apyMySupplyQBT.toString()), UNIT)
            marketValue.apyBorrow = toDisplayRatio(new BigNumber(info.apyBorrow.toString()), UNIT)
            marketValue.apyBorrowQBT = toDisplayRatio(new BigNumber(info.apyBorrowQBT.toString()), UNIT)
            marketValue.apyMyBorrowQBT = toDisplayRatio(new BigNumber(info.apyMyBorrowQBT.toString()), UNIT)
            marketValue.collateralFactor = new BigNumber(info.collateralFactor.toString())
            marketValue.liquidity = new BigNumber(info.liquidity.toString())
            marketValue.liquidityInUSD = marketValue.liquidity.times(prices[market.underlying]).div(UNIT)
            marketValue.supply = new BigNumber(info.supply.toString())
            marketValue.supplyInUSD = marketValue.supply.times(prices[market.underlying]).div(UNIT)
            marketValue.totalSupply = new BigNumber(info.totalSupply.toString())
            marketValue.supplyBoosted = new BigNumber(info.supplyBoosted.toString())
            marketValue.totalSupplyBoosted = new BigNumber(info.totalSupplyBoosted.toString())
            marketValue.borrow = new BigNumber(info.borrow.toString())
            marketValue.borrowInUSD = marketValue.borrow.times(prices[market.underlying]).div(UNIT)
            marketValue.totalBorrow = new BigNumber(info.totalBorrow.toString())
            marketValue.borrowBoosted = new BigNumber(info.borrowBoosted.toString())
            marketValue.totalBorrowBoosted = new BigNumber(info.totalBorrowBoosted.toString())
        } catch (e) {
            if (window.dev) {
                console.warn(market.name, e)
            }
        }
        return marketValue
    } catch {
        return null
    }
}

export const marketEnter = async (qubit: Qubit, market: MarketInfo): Promise<TransactionResponse> => {
    try {
        const qoreContract = qubit.contracts.getQoreContract()
        const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.enterMarkets([market.address]))
        return await qoreContract.enterMarkets([market.address], { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, marketEnter.name)
        return undefined
    }
}

export const marketExit = async (qubit: Qubit, market: MarketInfo): Promise<TransactionResponse> => {
    try {
        const qoreContract = qubit.contracts.getQoreContract()
        const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.exitMarket(market.address))
        return await qoreContract.exitMarket(market.address, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, marketExit.name)
        return undefined
    }
}

export const marketSupply = async (qubit: Qubit, market: MarketInfo, amount: string): Promise<TransactionResponse> => {
    try {
        const amountWithDecimals = new BigNumber(amount).times(UNIT).toString()
        const qoreContract = qubit.contracts.getQoreContract()
        if (market.name === 'BNB') {
            const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.supply(market.address, amountWithDecimals, { value: amountWithDecimals }))
            return await qoreContract.supply(market.address, amountWithDecimals, { value: amountWithDecimals, ...gasOptions })
        } else {
            const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.supply(market.address, amountWithDecimals))
            return await qoreContract.supply(market.address, amountWithDecimals, { ...gasOptions })
        }
    } catch (ex) {
        console.warn('ex', ex, marketSupply.name)
        return undefined
    }
}

export const marketRedeem = async (qubit: Qubit, market: MarketInfo, amount: string): Promise<TransactionResponse> => {
    try {
        const amountWithDecimals = new BigNumber(amount).times(UNIT).toString()
        const qoreContract = qubit.contracts.getQoreContract()
        const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.redeemUnderlying(market.address, amountWithDecimals))
        return await qoreContract.redeemUnderlying(market.address, amountWithDecimals, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, marketRedeem.name)
        return undefined
    }
}

export const marketRedeemAll = async (qubit: Qubit, market: MarketInfo, amount: string): Promise<TransactionResponse> => {
    try {
        const amountWithDecimals = new BigNumber(amount).times(UNIT).toString()
        const qoreContract = qubit.contracts.getQoreContract()
        const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.redeemToken(market.address, amountWithDecimals))
        return await qoreContract.redeemToken(market.address, amountWithDecimals, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, marketRedeem.name)
        return undefined
    }
}

export const marketBorrow = async (qubit: Qubit, market: MarketInfo, amount: string): Promise<TransactionResponse> => {
    try {
        const amountWithDecimals = new BigNumber(amount).times(UNIT).toString()
        const qoreContract = qubit.contracts.getQoreContract()
        const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.borrow(market.address, amountWithDecimals))
        return await qoreContract.borrow(market.address, amountWithDecimals, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, marketBorrow.name)
        return undefined
    }
}

export const marketRepay = async (qubit: Qubit, market: MarketInfo, amount: string): Promise<TransactionResponse> => {
    try {
        const amountWithDecimals = new BigNumber(amount).times(UNIT).toString()
        const qoreContract = qubit.contracts.getQoreContract()
        
        if (market.name === 'BNB') {
            const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.repayBorrow(market.address, amountWithDecimals, { value: amountWithDecimals }))
            return await qoreContract.repayBorrow(market.address, amountWithDecimals, { value: amountWithDecimals, ...gasOptions })
        } else {
            const gasOptions = await qubit.estimateTxGas(qoreContract.estimateGas.repayBorrow(market.address, amountWithDecimals))
            return await qoreContract.repayBorrow(market.address, amountWithDecimals, { ...gasOptions })
        }
    } catch (ex) {
        console.warn('ex', ex, marketBorrow.name)
        return undefined
    }
}

/**
 * Portfolio
 * */
export const getPortfolio = async (qubit: Qubit): Promise<PortfolioInfo> => {
    const portfolio = {
        supplyInUSD: ZERO, borrowInUSD: ZERO, limitInUSD: ZERO, limitUsed: 0,
        apyNet: 0, apySupply: 0, apySupplyQBT: 0, apyBorrow: 0, apyBorrowQBT: 0
    }
    
    const account = qubit.account ? qubit.account : EMPTY_ADDRESS
    try {
        const dashboard = qubit.contracts.getDashboardContract()
        const gasOptions = await qubit.estimateTxGas(dashboard.portfolioDataOf(account))
        const result = await dashboard.portfolioDataOf(account, { ...gasOptions })
        
        portfolio.supplyInUSD = new BigNumber(result.supplyInUSD.toString())
        portfolio.borrowInUSD = new BigNumber(result.borrowInUSD.toString())
        portfolio.limitInUSD = new BigNumber(result.limitInUSD.toString())
        portfolio.limitUsed = toDisplayRatio(portfolio.borrowInUSD, portfolio.limitInUSD)
        portfolio.apyNet = toDisplayRatio(new BigNumber(result.userApy.toString()), UNIT)
        portfolio.apySupply = toDisplayRatio(new BigNumber(result.userApySupply.toString()), UNIT)
        portfolio.apySupplyQBT = toDisplayRatio(new BigNumber(result.userApySupplyQBT.toString()), UNIT)
        portfolio.apyBorrow = toDisplayRatio(new BigNumber(result.userApyBorrow.toString()), UNIT)
        portfolio.apyBorrowQBT = toDisplayRatio(new BigNumber(result.userApyBorrowQBT.toString()), UNIT)
    } catch (ex) {
        console.warn('ex', ex, getPortfolio.name)
    }
    return portfolio
}

export const getAvgBoost = async (qubit: Qubit): Promise<any> => {
    let avgBoost = 0
    const account = qubit.account ? qubit.account : EMPTY_ADDRESS
    
    try {
        const dashboard = qubit.contracts.getDashboardContract()
        const result = await dashboard.getAvgBoost(account)
        avgBoost = (new BigNumber(result.toString()).div(UNIT)).toNumber()
    } catch (ex) {
        console.warn('ex', ex, getAvgBoost.name)
    }
    return avgBoost
}

/**
 * QBT
 * */
export const getQBTUnclaimed = async (qubit: Qubit): Promise<BigNumber> => {
    if (!qubit || !qubit.account) return ZERO
    try {
        const account = qubit.account ? qubit.account : EMPTY_ADDRESS
        const dashboard = qubit.contracts.getDashboardContract()
        const unclaimed = await dashboard.getUnclaimedQBT(account)
        
        return new BigNumber(unclaimed.toString())
    } catch (ex) {
        console.warn('ex', ex, getQBTUnclaimed.name)
        return ZERO
    }
}

export const claimQBT = async (qubit: Qubit): Promise<TransactionResponse> => {
    try {
        const account = qubit.account ? qubit.account : EMPTY_ADDRESS
        const distributorContract = qubit.contracts.getDistributorContract()
        const gasOptions = await qubit.estimateTxGas(distributorContract.estimateGas['claimQubit(address)'](account))
        return await distributorContract['claimQubit(address)'](account, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, claimQBT.name)
        return undefined
    }
}

/** Auction */
export const getAuction = async (qubit: Qubit): Promise<Auction> => {
    try {
        const presaleContract = await qubit.contracts.getPresaleReadOnlyContract()
        const result = await presaleContract.presaleDataOf(qubit && qubit.account ? qubit.account : AddressZero)
        
        const userLpAmount = new BigNumber(result.userLpAmount.toString())
        const totalLpAmount = new BigNumber(result.totalLpAmount.toString())
        const qbtBnbLpAmount = new BigNumber(result.qbtBnbLpAmount.toString())
        const userAllocation = toDisplayRatio(userLpAmount, totalLpAmount)
        return {
            startTime: result.startTime.toNumber(),
            endTime: result.endTime.toNumber(),
            userLpAmount: userLpAmount,
            totalLpAmount: totalLpAmount,
            refundLpAmount: new BigNumber(result.refundLpAmount.toString()),
            qbtBnbLpAmount: qbtBnbLpAmount,
            userAllocation: userAllocation,
            userReceived: qbtBnbLpAmount.times(userLpAmount).dividedBy(totalLpAmount),
            claimedOf: result.claimedOf
        }
    } catch (ex) {
        return {
            startTime: 1626228000, endTime: 1626652800,
            userLpAmount: ZERO,
            totalLpAmount: ZERO,
            refundLpAmount: ZERO,
            qbtBnbLpAmount: ZERO,
            userAllocation: 0,
            userReceived: ZERO,
            claimedOf: false
        }
    }
}

export const approvePresale = async (qubit: Qubit): Promise<TransactionResponse> => {
    try {
        const maxUint256 = MaxUint256.toString()
        const tokenContract = qubit.contracts.getBEP20Contract(BUNNY_BNB)
        const gasOptions = await qubit.estimateTxGas(tokenContract.estimateGas.approve(Contracts.Presale, maxUint256))
        return await tokenContract.approve(Contracts.Presale, maxUint256, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, approvePresale.name)
        return undefined
    }
}

export const joinAuction = async (qubit: Qubit, amount: string): Promise<TransactionResponse> => {
    try {
        const amountWithDecimals = new BigNumber(amount).times(UNIT).toString()
        const rocketBunny = qubit.contracts.getPresaleContract()
        const gasOptions = await qubit.estimateTxGas(rocketBunny.estimateGas.deposit(amountWithDecimals))
        return await rocketBunny.deposit(amountWithDecimals, { ...gasOptions })
    } catch (ex) {
        console.warn('ex', ex, joinAuction.name)
        return undefined
    }
}

