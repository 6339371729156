import { Contract, ContractInterface } from '@ethersproject/contracts'
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import { Contracts } from './lib/constants'

import DashboardAbi from './lib/abi/Dashboard.json'
import PriceCalculatorAbi from './lib/abi/PriceCalculator.json'
import QoreAbi from './lib/abi/Qore.json'
import ERC20Abi from './lib/abi/ERC20.json'
import PresaleAbi from './lib/abi/Presale.json'
import LockerAbi from './lib/abi/Locker.json'
import DistributorAbi from './lib/abi/Distributor.json'


export class QubitContracts {
    readonly library: Web3Provider
    readonly bsc: JsonRpcProvider
    
    private readonly dashboard: Contract
    private readonly calculator: Contract
    private readonly locker: Contract
    private readonly qore: Contract
    private readonly distributor: Contract
    private readonly presale: Contract
    
    constructor(library: Web3Provider, bsc: JsonRpcProvider) {
        this.library = library
        this.bsc = bsc
        
        this.dashboard = new Contract(Contracts.Dashboard, DashboardAbi as ContractInterface, bsc)
        this.calculator = new Contract(Contracts.PriceCalculator, PriceCalculatorAbi as ContractInterface, bsc)
        this.locker = new Contract(Contracts.Locker, LockerAbi as ContractInterface, library.getSigner())
        this.qore = new Contract(Contracts.Qore, QoreAbi as ContractInterface, library.getSigner())
        this.distributor = new Contract(Contracts.Distributor, DistributorAbi as ContractInterface, library.getSigner())
    }
    
    getBEP20Contract(address: string): Contract {
        return new Contract(address, ERC20Abi, this.library.getSigner())
    }
    
    getBEP20ReadOnlyContract(address: string): Contract {
        return new Contract(address, ERC20Abi, this.bsc)
    }
    
    getDashboardContract(): Contract {
        return this.dashboard
    }
    
    getPriceCalculatorContract(): Contract {
        return this.calculator
    }
    
    getLockerContract(): Contract {
        return this.locker
    }
    
    getQoreContract(): Contract {
        return this.qore
    }
    
    getDistributorContract(): Contract {
        return this.distributor
    }
    
    getPresaleReadOnlyContract(): Contract {
        return new Contract(Contracts.Presale, PresaleAbi as ContractInterface, this.bsc)
    }
    
    getPresaleContract(): Contract {
        return new Contract(Contracts.Presale, PresaleAbi as ContractInterface, this.library.getSigner())
    }
}
