import { AbstractConnector } from '@web3-react/abstract-connector'
import { bscConnector, injected, walletconnect } from './connectors'


export interface WalletInfo {
    connector?: AbstractConnector
    name: string
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    Metamask: {
        connector: injected,
        name: 'Metamask'
    },
    TrustWallet: {
        connector: injected,
        name: 'Trust Wallet'
    },
    MathWallet: {
        connector: injected,
        name: 'Math Wallet'
    },
    BinanceWallet: {
        connector: bscConnector,
        name: 'Binance Chain Wallet'
    },
    WalletConnect: {
        connector: walletconnect,
        name: 'Wallet Connect'
    }
}

export const NetworkContextName = 'NETWORK'
