import React from 'react'
import { assetLogos } from '../../assets/logos'
import './LocalLoader.scss'


const LocalLoader: React.FC = () => {
    return (
        <div className='local-loader no-select'>
            <img src={assetLogos.symbolYellow} alt='loading-icon'/>
        </div>
    )
}

export default LocalLoader
