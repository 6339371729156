import React, { createContext } from 'react'
import { JsonRpcProvider } from '@ethersproject/providers'
import { bscRPC } from '../../connections/connectors'


export interface Web3SideProviderContext {
    bsc?: JsonRpcProvider
}

export const Context = createContext<Web3SideProviderContext>({
    bsc: undefined
})

const bscLibrary = new JsonRpcProvider(bscRPC)

const Web3SideProvider: React.FC = ({ children }) => {
    return <Context.Provider value={{ bsc: bscLibrary }}>{children}</Context.Provider>
}

export default Web3SideProvider
