import React, {useState} from 'react'

import './PeriodButton.scss'

interface PeriodButtonProps {
    handlePeriod: (p: number) => void
}

const PeriodButton: React.FC<PeriodButtonProps> = ({handlePeriod}) => {

    const [period, setPeriod] = useState(0)


    return (
        <div className='same-line period-wrapper'>
            <div className={'period ' + (period === 0 ? 'clicked' : '')} onClick={() => {
                setPeriod(0);
                handlePeriod(0);
            }}>Day</div>
            <div className={'period ' + (period === 1 ? 'clicked' : '')} onClick={() => {
                setPeriod(1);
                handlePeriod(1);
            }}>Week</div>
        </div>
    )
}

export default PeriodButton
