import React, { useMemo } from 'react'
import { LockerInfoWithValue } from '../../../../qubit/lib/types'
import { toDisplayBalanceComma, toDisplayPercent } from '../../../../qubit/utils'
import useLocker from '../../../../hooks/pools/useLocker'
import usePortfolio from '../../../../hooks/pools/usePortfolio'
import { assetIcons } from '../../../../assets/icons'
import './InfoPortfolio.scss'
import Information from "../../../../components/Information";
import BasicWrapper from "../../../../components/BasicWrapper/BasicWrapper";

const InfoPortfolio: React.FC = () => {
    const { portfolio, avgBoost } = usePortfolio()
    const locker: LockerInfoWithValue = useLocker()


    const borrowLimitIndicatorStyles = useMemo(() => {
        if (portfolio.limitUsed === 0) {
            return { left: '1%' }
        } else if (portfolio.limitUsed > 80) {
            return { right: `${100 - portfolio.limitUsed}%`, color: 'white' }
        } else {
            return { left: `${portfolio.limitUsed}%` }
        }
    }, [portfolio])

    const supplyTopMarket = [
            {
                name: "USDT",
                percentage: 33.45
            },
            {
                name: "DAI",
                percentage: 28.60
            },
            {
                name: "ETH",
                percentage: 18.89
            }
        ]

    const borrowTopMarket = [
        {
            name: "DAI",
            percentage: 43.14
        },
        {
            name: "USDC",
            percentage: 42.60
        },
        {
            name: "USDT",
            percentage: 5.89
        }
    ]

    const topMarketView = (list: {name: String, percentage: number}[]) =>
        list.map((market) =>
            <div className='bar-wrapper'>
                <div className='backdrop'/>
                <div className='bar' style={{ clipPath: `polygon(0 0, ${portfolio.limitUsed > 0 ? portfolio.limitUsed : 1}% 0, ${portfolio.limitUsed > 0 ? portfolio.limitUsed : 1}% 100%, 0 100%)` }}/>
                <div className='top-series'>
                    <span className='top-market-label'>{market.name}</span>
                    <span className='top-series-value number' style={borrowLimitIndicatorStyles}>{market.percentage.toFixed(2)}%</span>
                </div>
            </div>
        )



    return (
        <div className='info-portfolio-wrapper'>
            <span className='info-mobile-label'>Market</span>
            <div className='info-portfolio'>
                <div className='total supply'>
                    <div className='info-label-underlined'>Total Supply</div>
                    <div className='total-number same-line space'>
                        <div className='number'>${toDisplayBalanceComma(portfolio.borrowInUSD)}</div>
                        <BasicWrapper>
                            <div className='number plus'>{0.16 > 0 ? '+' : ''}{0.16}%</div>
                        </BasicWrapper>
                    </div>
                </div>

                <div className='total borrow'>
                    <div className='info-label-underlined'>Total Borrow</div>
                    <div className='total-number same-line space'>
                        <div className='number'>${toDisplayBalanceComma(portfolio.borrowInUSD)}</div>
                        <BasicWrapper>
                            <div className='number minus'>{-0.16 > 0 ? '+' : ''}{-0.16}%</div>
                        </BasicWrapper>
                    </div>
                </div>

                <div className='top-markets supply'>
                    <div className='top-market-label title'>Top 3 markets</div>
                    {topMarketView(supplyTopMarket)}
                </div>

                <div className='top-markets borrow'>
                    <div className='top-market-label title'>Top 3 markets</div>
                    {topMarketView(borrowTopMarket)}
                </div>
            </div>

            <div className='info-mobile-label'>qScore</div>
            <div className='q-score'>
                <div className='q-score-item totalQscore'>
                    <div className='info-label-underlined'>Total qScore</div>
                    <div className='q-score-item-value number'>{toDisplayBalanceComma(locker.totalScore)}</div>
                </div>

                <div className='q-score-item avgQscore'>
                    <div className='info-label-underlined'>Average qScore</div>
                    <div className='q-score-item-value number'>{toDisplayBalanceComma(locker.score)}</div>
                </div>

                <div className='q-score-item avgBoost'>
                    <div className='relative'>
                        <div className='info-label-underlined'>Average Boost</div>
                        <Information text="Explanation of Average Boost" >
                            <img className='info-icon' src={assetIcons.info} alt='Avg Boost Info'/>
                        </Information>
                    </div>

                    <div className='q-score-item-rocket'>
                        <img src={assetIcons.iconRocket} alt='Avg Boost'/>
                        <span className='number'>x {locker ? toDisplayPercent(avgBoost) : '##.#'}</span>
                    </div>



                </div>
            </div>

        </div>
    )
}

export default InfoPortfolio
