import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from './NetworkConnector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'


export const bscRPCs = [
    // 'https://bsc-dataseed.binance.org',
    // 'https://bsc-dataseed1.defibit.io',
    // 'https://bsc-dataseed1.ninicoin.io',
    
    // TODO testnet
    'https://data-seed-prebsc-1-s1.binance.org:8545/'
]

export const lclRPC = 'http://localhost:8545'
export const bscRPC = window.dev ? lclRPC : bscRPCs[Math.floor(Math.random() * bscRPCs.length)]

// export const NETWORK_CHAIN_ID = window.dev ? 31337 : 56
export const NETWORK_CHAIN_ID = window.dev ? 31337 : 97 // TODO testnet

export interface ChainInfo {
    chainId: string
    chainName: string
    rpcUrl: string
    nativeCurrency: {
        name: string
        symbol: string
        decimals: number
    },
    blockExplorerUrl: string
}

export const ChainInfo: Record<number, ChainInfo> = {
    // 56: {
    //     chainId: '0x38',
    //     chainName: 'Binance Smart Chain',
    //     rpcUrl: bscRPC,
    //     nativeCurrency: {
    //         name: 'Binance Coin',
    //         symbol: 'BNB',
    //         decimals: 18
    //     },
    //     blockExplorerUrl: 'https://bscscan.com'
    // },
    97: {
        chainId: '0x61',
        chainName: 'BSC Testnet',
        rpcUrl: bscRPC,
        nativeCurrency: {
            name: 'Binance Coin',
            symbol: 'BNB',
            decimals: 18
        },
        blockExplorerUrl: 'https://testnet.bscscan.com'
    },
    31337: {
        chainId: '0x7a69',
        chainName: 'Local BSC',
        rpcUrl: lclRPC,
        nativeCurrency: {
            name: 'Binance Coin',
            symbol: 'LCL',
            decimals: 18
        },
        blockExplorerUrl: 'https://bscscan.com'
    }
}

export enum ChainId {
    ETH = 1,
    // BSC = 56,
    BSC = 97, // TODO testnet
    LCL = 31337
}

export const networkShortName = (chainId: number) => {
    const info = ChainInfo[chainId]
    return info ? info.nativeCurrency.symbol : 'Unknown'
}

export const network = new NetworkConnector({
    urls: {
        // 56: bscRPC,
        97: bscRPC,
        31337: lclRPC
    },
    defaultChainId: NETWORK_CHAIN_ID
})

export const injected = new InjectedConnector({
    supportedChainIds: [
        // 56,
        97, 31337
    ]
})

export const walletconnect = new WalletConnectConnector({
    rpc: { [NETWORK_CHAIN_ID]: bscRPC },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: 3000
})

export const bscConnector = new BscConnector({
    supportedChainIds: [
        // 56,
        97
    ]
})
