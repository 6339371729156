import React from 'react'


const Footer: React.FC = () => {
    return (
        <div className='page-footer-nav no-select'>
            <a className='item clickable' href='https://github.com/PancakeBunny-finance/qubit-finance' target='_blank' rel='noopener noreferrer'>GITHUB</a>
            <a className='item clickable' href='https://link.medium.com/HHdzwh08Rhb' target='_blank' rel='noopener noreferrer'>MEDIUM</a>
            <a className='item clickable' href='https://qubit-finance.gitbook.io/qubit-finance/' target='_blank' rel='noopener noreferrer'>DOCS</a>
        </div>
    )
}

export default Footer
