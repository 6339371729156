import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {toDisplayPercent} from '../../../../../qubit/utils'
import {Line, Bar} from 'react-chartjs-2'
import './InfoDetailPortfolio.scss'
import {useLocation} from "react-router-dom";
import PeriodButton from "../../../../../components/PeriodButton/PeriodButton";
import CustomLegend from "../../../../../components/CustomLegend/CustomLegend";
import InfoDetailTitle from "./InfoDetailTitle";
import GroupDivider from "../../../../../components/GroupDivider";

const InfoDetailPortfolio: React.FC = () => {
    const location = useLocation()

    // period module
    const period = [30, 24]
    const [periodIdx, setPeriodIdx] = useState(0)

    const handlePeriod = (p: number) => {
        setPeriodIdx(p)
    }

    // to draw chart
    const [labels, setLabels] = useState([])
    const [chartList, setChartList] = useState({})

    useEffect(() => {
        let today = moment().startOf('day')
        let tempToday = today;
        let tempLabels = []

        if (periodIdx === 0) {
            for (let i=0 ; i < period[periodIdx] ; i++) {
                tempLabels.unshift(tempToday.format('MMM Do'))
                tempToday = tempToday.subtract(1, 'days')
            }
            setLabels(tempLabels)
        }
        else if (periodIdx === 1) {
            for (let i=0 ; i < period[periodIdx] ; i++) {
                tempLabels.unshift(tempToday.format("MMM Do"))
                tempToday = tempToday.subtract(7, 'days')
            }
            setLabels(tempLabels)
        }
    }, [periodIdx, location.state])

    useEffect(() => {
        setChartList({
            labels: labels,
            datasets: [
                {
                    label: 'Supply',
                    backgroundColor: '#0094FF',
                    borderColor: '#0094FF',
                    borderWidth: 2,
                    data: [65, 59, 80, 81, 56, 20, 33, 55, 46 ,50,
                        40, 50, 60, 20, 13, 53, 22, 12, 25, 98,
                        50, 44, 75, 78, 51, 21, 43, 50, 47, 42],
                },
                {
                    label: 'Borrow',
                    backgroundColor: '#33383B',
                    borderColor: '#33383B',
                    borderWidth: 2,
                    data: [55, 49, 70, 61, 46, 10, 23, 44, 43, 20, 11,
                        31, 42, 15, 35, 12, 7, 8, 10, 25, 55,
                        61, 10, 20, 5, 40, 19, 2, 50, 11, 32],
                }
            ],
        })

    }, [labels, location.state])


    // not to use same code twice
    const apyView = (sob: String) =>
        <div className='apy-wrapper'>
            <div className='apy-title'>
                <div className='apy-label'>{sob} APY</div>
                <div className={"apy-number number " + (sob === "Supply" ? 'supply' : '')}>9.82%</div>
            </div>

            <div className='apy-item net'>
                <span className='label-underlined'>NET Rate</span>
                <span className='number'>{toDisplayPercent(0)}%</span>
            </div>
            <div className='apy-item reward'>
                <span className='label-underlined'>Reward APY</span>
                <span className='number'>{toDisplayPercent(0)}%</span>
            </div>
            <div className='apy-item total'>
                <span className='label-underlined'>Total {sob}</span>
                <span className='number'>{toDisplayPercent(0)}%</span>
            </div>
        </div>


    return (
        <div className='info-detail-portfolio-wrapper'>
            <InfoDetailTitle />

            <div className='market-portfolio'>
                <div className='chart'>
                    <PeriodButton handlePeriod={handlePeriod} />
                    <CustomLegend />

                    <Line data={chartList}
                          width={398}
                          height={70}
                          options={{
                              scales: {
                                  x: {
                                      display: false,
                                  },
                                  y: {
                                      display: false
                                  }
                              },
                              elements: {
                                  point: {
                                      radius: 0,
                                      hoverRadius: 2
                                  },
                                  line: {
                                      border: 5,
                                      borderWidth: 5
                                  }
                              },
                              plugins: {
                                  filler: {
                                      propagate: false
                                  },
                                  legend:{
                                      display: false
                                  },
                              },
                              interaction: {
                                  intersect: false
                              },


                          }}/>
                    <Bar data={chartList}
                         width={398}
                         height={100}
                         options={{
                             elements: {
                                 bar: {
                                     borderWidth: 1,
                                 }
                             },
                             scales: {
                                 ticks: {
                                     display: false

                                 },
                                 grid: {
                                     display: false,
                                     drawTicks: false
                                 },
                                 x: {
                                     // scaleLabel: {
                                     //     display: true
                                     // },
                                     gridLines: [{
                                         zeroLineColor: '#Fff'
                                     }],
                                     display: false,

                                     title: {
                                         display: true
                                     },
                                     ticks: {
                                         stepSize: 1,
                                         showLabelBackdrop: true
                                     },
                                     font: {
                                         size: 6
                                     }
                                 },
                                 y: {
                                     display: false
                                 }
                             },
                             plugins: {
                                 legend:{
                                     display: false
                                 },
                                 label: {
                                     fontSize: '6px'
                                 },
                             },
                             title:{
                                 display: false,
                             },


                             animations: {
                                 duration: false
                             },

                         }}
                    />
                </div>

                <div className='supply'>
                    {apyView("Supply")}
                </div>

                <div className='borrow'>
                    {apyView("Borrow")}
                </div>

            </div>

        </div>
    )
}

export default InfoDetailPortfolio
