import { singletonHook } from 'react-singleton-hook'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getAvgBoost, getPortfolio, ZERO } from '../../qubit/utils'
import { PortfolioInfo } from '../../qubit/lib/types'
import useQubit from '../useQubit'
import useBlock from '../useBlock'

const init: PortfolioInfo = {
    supplyInUSD: ZERO, borrowInUSD: ZERO, limitInUSD: ZERO, limitUsed: 0,
    apyNet: 0, apySupply: 0, apySupplyQBT: 0, apyBorrow: 0, apyBorrowQBT: 0
}

const usePortfolioImpl = () => {
    const mountRef = useRef(false)
    const [ portfolio, setPortfolio ] = useState(init)
    const [ avgBoost, setAvgBoost ] = useState(0)
    
    const qubit = useQubit()
    const { bscBlock } = useBlock()
    
    const handlePortfolio = useCallback(async () => {
        const result = await getPortfolio(qubit)
        const boost = await getAvgBoost(qubit)
        
        if (!mountRef.current) return
        setPortfolio(result)
        setAvgBoost(boost)
    }, [qubit])
    
    useEffect(() => {
        if (qubit) {
            handlePortfolio()
        }
    }, [ qubit, bscBlock, handlePortfolio ])
    
    useEffect(() => {
        mountRef.current = true
        
        return () => {
            mountRef.current = false
        }
    })
    
    return { portfolio, avgBoost }
}

const usePortfolio = singletonHook({ portfolio: init, avgBoost: 0 }, usePortfolioImpl)
export default usePortfolio
