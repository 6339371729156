import React, {useEffect, useState} from 'react'
import { Card } from '../../../../../components/Card'
import './InfoDetailChartGroup.scss'
import PeriodButton from "../../../../../components/PeriodButton/PeriodButton";
import moment from "moment";
import {Line} from "react-chartjs-2";
import CustomLegend from "../../../../../components/CustomLegend/CustomLegend";



const InfoDetailChartGroup: React.FC = () => {
    const [labels, setLabels] = useState([])
    const period = [30, 24]

    const [periodIdx, setPeriodIdx] = useState(0)

    const handlePeriod = (p: number) => {
        setPeriodIdx(p)
    }

    const [graphList, setGraphList] = useState({})

    useEffect(() => {

        let today = moment().startOf('day')
        let tempToday = today;
        let tempLabels = []

        if (periodIdx === 0) {
            for (let i=0 ; i < period[periodIdx] ; i++) {
                tempLabels.unshift(tempToday.format('MMM Do'))
                tempToday = tempToday.subtract(1, 'days')
            }
            setLabels(tempLabels)
        }
        else if (periodIdx === 1) {
            for (let i=0 ; i < period[periodIdx] ; i++) {
                tempLabels.unshift(tempToday.format("MMM Do"))
                tempToday = tempToday.subtract(7, 'days')
            }
            setLabels(tempLabels)
        }


    }, [periodIdx])

    useEffect(() => {
        setGraphList({
            labels: labels,
            datasets: [
                {
                    label: 'Supply',
                    backgroundColor: '#0094FF',
                    borderColor: '#0094FF',
                    borderWidth: 2,
                    data: [65, 59, 80, 81, 56, 20, 33, 55, 46 ,50,
                        40, 50, 60, 20, 13, 53, 22, 12, 25, 98,
                        50, 44, 75, 78, 51, 21, 43, 50, 47, 42],

                },
                {
                    label: 'Borrow',
                    backgroundColor: '#33383B',
                    borderColor: '#33383B',
                    borderWidth: 2,
                    data: [55, 49, 70, 61, 46, 10, 23, 44, 43, 20, 11,
                        31, 42, 15, 35, 12, 7, 8, 10, 25, 55,
                        61, 10, 20, 5, 40, 19, 2, 50, 11, 32],
                }
            ],
        })

    }, [labels])

    return (
        <Card>
            <div className='chart-group'>
                <div className='chart-group-relative'>
                    <PeriodButton handlePeriod={handlePeriod}/>
                    <CustomLegend />

                    <div className='chart-wrapper'>
                    <Line data={graphList}
                          width={352}
                          height={100}
                          options={{
                              scales: {
                                  x: {
                                      display: true,
                                  },
                                  y: {
                                      display: false
                                  }
                              },
                              elements: {
                                  point: {
                                      radius: 0,
                                      hoverRadius: 2
                                  },
                                  line: {
                                      border: 5,
                                      borderWidth: 5
                                  }
                              },
                              plugins: {
                                  filler: {
                                      propagate: false
                                  },
                                  legend:{
                                      display: false
                                  },
                              },
                              interaction: {
                                  intersect: false
                              },


                          }}/>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default InfoDetailChartGroup
