import React from 'react'

import './CustomLegend.scss'


const PeriodButton: React.FC = () => {

    return (
        <div className='legend-wrapper'>
            <div className='same-line'>
                <div className='circle supply'/>
                <div>Supply</div>
            </div>
            <div className='same-line'>
                <div className='circle'/>
                <div>Borrow</div>
            </div>
        </div>
    )
}

export default PeriodButton
