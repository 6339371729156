import { singletonHook } from 'react-singleton-hook'
import { useEffect, useState } from 'react'
import { BigNumber, getPrices, ZERO } from '../../qubit/utils'
import { supportedMarkets } from '../../qubit/lib/constants'
import useQubit from '../useQubit'


const init: Record<string, BigNumber> = {}
supportedMarkets.map(market => market.underlying).forEach((underlying: string) => init[underlying] = ZERO)

const usePricesImpl = () => {
    const [prices, setPrices] = useState(init)
    const qubit = useQubit()
    
    useEffect(() => {
        const interval = setInterval(async () => {
            if (qubit) {
                getPrices(qubit).then(prices => setPrices(prices))
            }
        }, 10000)
        
        if (qubit) {
            getPrices(qubit).then(prices => setPrices(prices))
        }
        return () => clearInterval(interval)
    }, [qubit, setPrices])
    
    return prices
}

const usePrices = singletonHook(init, usePricesImpl)
export default usePrices
