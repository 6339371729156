import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { SingletonHooksContainer } from 'react-singleton-hook'
import { Provider } from 'react-redux'

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { NetworkContextName } from './connections'
import getLibrary from './connections/getLibrary'

import FirebaseProvider from './contexts/Firebase'
import QubitProvider from './contexts/QubitProvider'
import ModalsProvider from './contexts/Modals'

import TransactionUpdater from './state/transactions/updater'
import Web3SideProvider from './contexts/Web3SideProvider'
import store from './state'

import Web3ReactManager from './components/Web3ReactManager'
import LocalLoader from './components/LocalLoader'
import Info from "./views/Info";


const TopBar = lazy(() => import('./components/TopBar'))
const Popups = lazy(() => import('./components/Popups'))
const TopAlert = lazy(() => import('./components/TopAlert/TopAlert'))
const Markets = lazy(() => import('./views/Markets'))
const Auction = lazy(() => import('./views/Auctions'))

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if ('ethereum' in window) {
    (window.ethereum as any).autoRefreshOnNetworkChange = false
}

const App: React.FC = () => {
    return (
        <Providers>
            <Suspense fallback={<LocalLoader/>}>
                <Router>
                    <TopAlert/>
                    <TopBar/>
                    
                    <Switch>
                        <Route path='/app' component={Markets}/>
                        <Route path='/info' component={Info}/>
                        <Route path='*'>
                            <Redirect to='/app'/>
                        </Route>
                    </Switch>
                </Router>
                
                <Popups/>
                <SingletonHooksContainer/>
            </Suspense>
        </Providers>
    )
}

const Web3Providers: React.FC = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
                <Web3SideProvider>
                    <Web3ReactManager>
                        {children}
                    </Web3ReactManager>
                </Web3SideProvider>
            </Web3ProviderNetwork>
        </Web3ReactProvider>
    )
}

const Providers: React.FC = ({ children }) => {
    return (
        <FirebaseProvider>
            <Provider store={store}>
                <Web3Providers>
                    <TransactionUpdater/>
                    <QubitProvider>
                        <ModalsProvider>
                            {children}
                        </ModalsProvider>
                    </QubitProvider>
                </Web3Providers>
            </Provider>
        </FirebaseProvider>
    )
}

export default App
