import iconClose from './icon-close.svg'
import iconWalletArrow from './icon-wallet-arrow.svg'
import iconForward from './icon-forward.svg'
import iconLocker from './icon-locker.svg'
import iconTxConfirm from './icon-tx-confirm.svg'
import iconTxWarning from './icon-tx-warning.svg'
import iconDropdown from './icon-dropdown.svg'
import iconInfo from './icon-info.svg'
import iconBack from './icon-back.svg'

import iconDiscord from './icon-discord.png'
import iconTwitter from './icon-twitter.svg'
import iconTelegram from './icon-telegram.svg'
import iconGithub from './icon-github.svg'
import iconNotion from './icon-notion.svg'
import iconInstagram from './icon-instagram.svg'

import iconHowTo from './icon-howto.svg'
import iconExternalLink from './icon-external-link.svg'
import iconRocket from './icon-rocket.svg'

export const assetIcons: Record<string, string> = {
    close: iconClose,
    walletArrow: iconWalletArrow,
    forwardArrow: iconForward,
    locker: iconLocker,
    txConfirm: iconTxConfirm,
    txWarning: iconTxWarning,
    dropdown: iconDropdown,
    info: iconInfo,
    back: iconBack,
    
    discord: iconDiscord,
    twitter: iconTwitter,
    telegram: iconTelegram,
    github: iconGithub,
    notion: iconNotion,
    instagram: iconInstagram,
    
    iconHowTo: iconHowTo,
    iconExternalLink: iconExternalLink,
    iconRocket: iconRocket
}
