import React from 'react'
import './BasicWrapper.scss'


const BasicWrapper: React.FC = ({ children }) => {

    return (
        <div className='basic-wrapper'>
            <div>{children}</div>
        </div>

    )
}

export default BasicWrapper
