import React from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import { Page, PageContent } from '../../components/Page'
import InfoPortfolio from './components/InfoPortfolio'
import InfoMarketGroup from './components/InfoMarketGroup'
import './Info.scss'
import InfoDetail from "./InfoDetail";



const Info: React.FC = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>

            <Route exact path={path}>
                <Page>
                    <InfoPortfolio/>
                    <PageContent>
                        <div className='info-group-wrapper'>
                            <div className='info-group-label'>All Markets</div>
                            <InfoMarketGroup />
                        </div>
                    </PageContent>
                </Page>
            </Route>
            <Route path={`${path}/:id`}>
                <Page bgColor="deep-base">
                    <InfoDetail />
                </Page>
            </Route>

        </Switch>
    )
}

export default Info
