import React from 'react'
import { Card } from '../../../../../components/Card'
import './InfoDetailGroup.scss'
import GroupDivider from "../../../../../components/GroupDivider";
import GroupColDivider from "../../../../../components/GroupDivider/GroupColDivider";



const InfoDetailGroup: React.FC = () => {

    return (
        <Card>
            <div className='market-detail-group'>
                <div className='same-line block no-select'>
                    <span>Price</span>
                    <div className='block-content'>$1.00</div>
                </div>
                <GroupDivider />

                <div className='same-line block no-select'>
                    <span>Market Liquidity</span>
                    <div className='block-content'>1,915,011,886 USDC</div>
                </div>
                <GroupDivider />

                <div className='same-line'>
                    <div className='same-line block no-select'>
                        <span># of Suppliers</span>
                        <div className='block-content'>218211</div>
                    </div>
                    <GroupColDivider />
                    <div className='same-line block no-select'>
                        <span># of Borrowers</span>
                        <div className='block-content'>2882</div>
                    </div>
                </div>
                <GroupDivider />

                <div className='same-line block USDC no-select'>
                    <span>USDC Borrow Cap</span>
                    <div className='block-content'>No Limit</div>
                </div>
                <GroupDivider />

                <div className='same-line block interest no-select'>
                    <span>Interest Paid/Day</span>
                    <div className='block-content'>$264,936.55</div>
                </div>
                <GroupDivider />

                <div className='same-line block reserves no-select'>
                    <span>Reserves</span>
                    <div className='block-content'>6,715,209 USDC</div>
                </div>
                <GroupDivider />

                <div className='same-line'>
                    <div className='same-line block reserveFactor no-select'>
                        <span>Reserve Factor</span>
                        <div className='block-content'>7%</div>
                    </div>
                    <GroupColDivider />
                    <div className='same-line block no-select'>
                        <span>Collateral Factor</span>
                        <div className='block-content'>75%</div>
                    </div>
                </div>
                <GroupDivider />

                <div className='same-line block no-select'>
                    <span>cUSDC Minted</span>
                    <div className='block-content'>213,317,716,722</div>
                </div>
                <GroupDivider />

                <div className='same-line block no-select'>
                    <span>Exchange Rate</span>
                    <div className='block-content'>1 USDC = 45.284606921637845 cUSDC</div>
                </div>

            </div>
        </Card>
    )
}

export default InfoDetailGroup
