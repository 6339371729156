import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import { QubitContracts } from './QubitContracts'


export default class Qubit {
    readonly library: Web3Provider
    readonly contracts: QubitContracts
    readonly account: string
    
    constructor(account: string, library: any, bsc: JsonRpcProvider) {
        this.library = library
        this.contracts = new QubitContracts(library, bsc)
        this.account = account
    }
    
    async estimateTxGas(execution: Promise<any>, gasMin: number = 0): Promise<{ gasLimit: string; }> {
        const gasOptions: { gasLimit: string; } = {
            gasLimit: null
        }
        
        try {
            const estimatedGas = await execution
            const bufferedGas = parseInt(estimatedGas.mul(125).div(100).toString())
            const normalizedGas = parseInt(((gasMin || 0) * 110 / 100).toString())
            gasOptions.gasLimit = Math.max(bufferedGas, normalizedGas).toString()
        } catch (ex) {
            gasOptions.gasLimit = '8000000'
        }
        return gasOptions
    }
}
