import React from 'react'
import './GroupDivider.scss'


const GroupDivider: React.FC = ({ children }) => {
    return (
        <div className='group-divider'/>

    )
}

export default GroupDivider